import axios from '../services/api.service';

export const userGetInitialization = async () => {

  try {
    const { data: response } = await axios.get('/auth/initialization')
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error)
  }
};

export const getUserInfo = () => async () => {
  try {
    await axios.get('/users/info')
  } catch (e) {
  }
}

export const userGetInfo = async () => {

  try {
    const { data: response } = await axios.get('/users/info')
    return response.data.result;
  } catch (error) {
    console.error(error)
  }
};

export const userPutInfo = async (data: object) => {
  try {
    await axios.put('/users/info', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const userUpdatePassword = async (data: object) => {
  try {
    await axios.put('/users/update-password', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const userForgotPassword = async (data: object) => {
  try {
    await axios.post('/users/forgot-password', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const userResetPassword = async (data: object) => {
  try {
    await axios.post('/users/reset-password', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const userPostCheckout = async (data: object) => {
  try {
    const { data: response } = await axios.post('/users/checkout', data);
    return response;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const userPostPortal = async (data: object) => {
  try {
    const { data: response } = await axios.post('/users/portal', data);
    return response;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};