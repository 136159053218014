import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { CssBaseline, ThemeProvider, CircularProgress } from '@material-ui/core';
import { initializeI18n } from './i18n';
import { RTL } from './components/rtl';
import { gtmConfig } from './config';
import { useSettings } from './contexts/settings-context';
import { useAuth } from './hooks/use-auth';
import gtm from './lib/gtm';
import { getRoutes } from './routes';
import { createCustomTheme } from './theme';
import { review1GetCustomers, review1GetCompany, review1GetCompanies, review1GetSolution } from './slices/review1Slice';
import { review2GetAll } from './slices/review2Slice';
import { review3GetAll } from './slices/review3Slice';
import { review4GetAll } from './slices/review4Slice';
import { RootState } from './store';
import { unknownGetAll } from './slices/unknownSlice';
import { reportsGetAll } from './slices/reportsSlice';
import { getLists } from './slices/listSlice';
import { viewCreateOne } from './api/view';

export const App: FC = () => {
  const { isInitialized, isAuthenticated, accessRights } = useAuth();
  const content = useRoutes(getRoutes(accessRights));
  const location = useLocation();
  const { settings } = useSettings();
  const { review1CustomerIsInitialized, review1CompanyIsInitialized } = useSelector((state: RootState) => state.review1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(review1GetCustomers());
      dispatch(review1GetSolution());
      dispatch(review1GetCompanies());
      dispatch(review1GetCompany());
      dispatch(review2GetAll());
      dispatch(review3GetAll());
      dispatch(review4GetAll());
      dispatch(unknownGetAll());
      dispatch(reportsGetAll());
      dispatch(getLists());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    gtm.initialize(gtmConfig);
    initializeI18n(settings.language);
  }, [settings]);

  useEffect(() => {
    console.log('Location changed', location.pathname);
    viewCreateOne({ url: location.pathname });
  }, [location]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        {/* {(isInitialized === true
          && review1CustomerIsInitialized === true
          && review1CompanyIsInitialized === true
          ) ? content : (
          <div style={{ width: '100vw', height: '100vh', textAlign: 'center' }}>
            <CircularProgress style={{ marginTop: '25%' }} />
          </div>
        )} */}

        {isInitialized === true ?
          (
            (isAuthenticated === true) ? (
              (review1CustomerIsInitialized === true && review1CompanyIsInitialized === true) ? (
                content
              ) : (
                <div style={{ width: '100vw', height: '100vh', textAlign: 'center' }}>
                  <CircularProgress style={{ marginTop: '25%' }} />
                </div>
              )
            ) : (
              content
            )
          ) : (
            <div style={{ width: '100vw', height: '100vh', textAlign: 'center' }}>
              <CircularProgress style={{ marginTop: '25%' }} />
            </div>
          )
        }
      </RTL>
    </ThemeProvider>
  );
};
